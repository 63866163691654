import { saveAs } from 'file-saver';
import { format } from 'date-fns';
import { PdfGenerator } from './pdfGenerator';
import type { ExportData } from '../types/report';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export class PDFGenerationError extends Error {
  constructor(message: string, public details?: { originalError: any }, public code?: string) {
    super(message);
    this.name = 'PDFGenerationError';
  }
}

const createTableOptions = (startY: number) => ({
  startY,
  margin: { left: 14, right: 14 },
  headStyles: { fillColor: [65, 84, 241] as [number, number, number] },
  alternateRowStyles: { fillColor: [245, 247, 250] as [number, number, number] },
  styles: { fontSize: 10 }
});

const PDF_CONSTANTS = {
  COLORS: {
    SECONDARY: [150, 150, 150] as [number, number, number]
  }
};

export const exportDailyReport = async (data: ExportData): Promise<void> => {
  try {
    // Validate input data
    if (!data || !data.consultations) {
      throw new PDFGenerationError('Missing required report data');
    }

    // Create PDF with error handling
    let pdfBlob: Blob;
    try {
      const pdfGenerator = new PdfGenerator(data, {
        pageSize: 'a4',
        orientation: 'portrait'
      });
      
      pdfBlob = await pdfGenerator.generate();
      if (!pdfBlob || !(pdfBlob instanceof Blob)) {
        throw new PDFGenerationError('Invalid PDF generation output');
      }
    } catch (genError) {
      throw new PDFGenerationError(
        'PDF generation failed',
        { originalError: genError },
        'PDF_GENERATION_ERROR'
      );
    }

    // Save file with error handling
    try {
      const fileName = `IMD_Care_Report_${format(new Date(), 'yyyy-MM-dd_HHmm')}.pdf`;
      saveAs(pdfBlob, fileName);
    } catch (saveError) {
      throw new PDFGenerationError(
        'Failed to save PDF file',
        { originalError: saveError },
        'PDF_SAVE_ERROR'
      );
    }
  } catch (error) {
    console.error('PDF Export Error:', error);
    
    if (error instanceof PDFGenerationError) {
      throw error;
    }
    
    throw new PDFGenerationError(
      'An unexpected error occurred while generating the report',
      { originalError: error },
      'UNEXPECTED_ERROR'
    );
  }
};

export const exportLongStayReport = async (patients: any[], options: { dateRange?: { startDate: string; endDate: string } }): Promise<void> => {
  try {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.width;
    let currentY = 15;

    // Add header
    doc.setFontSize(20);
    doc.text('Long Stay Patient Report', pageWidth / 2, currentY, { align: 'center' });
    
    currentY += 10;
    doc.setFontSize(12);
    doc.text(`Generated on: ${format(new Date(), 'dd/MM/yyyy HH:mm')}`, pageWidth / 2, currentY, { align: 'center' });

    if (options.dateRange) {
      currentY += 7;
      doc.text(
        `Period: ${format(new Date(options.dateRange.startDate), 'dd/MM/yyyy')} to ${format(new Date(options.dateRange.endDate), 'dd/MM/yyyy')}`,
        pageWidth / 2,
        currentY,
        { align: 'center' }
      );
    }
    
    currentY += 15;

    // Add patient table
    if (patients.length > 0) {
      autoTable(doc, {
        ...createTableOptions(currentY),
        head: [['Patient Name', 'MRN', 'Department', 'Doctor', 'Admission Date', 'Stay Duration']],
        body: patients.map(patient => {
          const admission = patient.admissions?.[0];
          if (!admission) return [];
          
          const stayDuration = Math.ceil(
            (new Date().getTime() - new Date(admission.admission_date).getTime()) / 
            (1000 * 60 * 60 * 24)
          );
          
          return [
            patient.name,
            patient.mrn,
            admission.department,
            admission.admitting_doctor?.name || 'Not assigned',
            format(new Date(admission.admission_date), 'dd/MM/yyyy'),
            `${stayDuration} days`
          ];
        })
      });

      currentY = (doc as any).lastAutoTable.finalY + 15;
    } else {
      doc.setFontSize(12);
      doc.text('No long stay patients found.', 14, currentY);
      currentY += 15;
    }

    // Add footer with page numbers
    const pageCount = (doc as any).internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setTextColor(...PDF_CONSTANTS.COLORS.SECONDARY);
      doc.text(
        `Page ${i} of ${pageCount}`,
        pageWidth / 2,
        doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }

    // Save the PDF
    doc.save(`long-stay-report-${format(new Date(), 'dd-MM-yyyy-HHmm')}.pdf`);
  } catch (error) {
    throw new PDFGenerationError(
      'Failed to generate long stay report',
      { originalError: error },
      'PDF_GENERATION_ERROR'
    );
  }
};