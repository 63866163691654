import React, { useState, useEffect } from 'react';
import { Download, FileText, RefreshCw, AlertCircle, BarChart2, Users, Calendar, Activity } from 'lucide-react';
import { usePatientStore } from '../stores/usePatientStore';
import { useConsultationStore } from '../stores/useConsultationStore';
import { useAppointmentStore } from '../stores/useAppointmentStore';
import { exportDailyReport } from '../utils/reportExport';
import ReportFilters from '../components/Reports/ReportFilters';
import ReportTable from '../components/Reports/ReportTable';
import ReportSummary from '../components/Reports/ReportSummary';
import StatCard from '../components/Reports/StatCard';
import DataChart from '../components/Reports/DataChart';
import type { ReportFilters as ReportFiltersType } from '../types/report';
import type { ExportData } from '../types/report';
import { toast } from 'react-hot-toast';
import { format } from 'date-fns';
import { PdfGenerator } from '../utils/pdfGenerator';
import { PDFGenerationError } from '../utils/reportExport';

const DailyReports: React.FC = () => {
  const { patients, fetchPatients } = usePatientStore();
  const { consultations, fetchConsultations } = useConsultationStore();
  const { appointments, fetchAppointments } = useAppointmentStore();
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [lastRefresh, setLastRefresh] = useState<Date>(new Date());

  const today = new Date().toISOString().split('T')[0];
  const [filters, setFilters] = useState<ReportFiltersType>({
    dateFrom: today,
    dateTo: today,
    reportType: 'daily',
    specialty: 'all',
    searchQuery: ''
  });

  const fetchData = async () => {
    setIsLoading(true);
    setLoadingError(null);
    try {
      await Promise.all([
        fetchPatients(true),
        fetchConsultations(),
        fetchAppointments()
      ]);
      setLastRefresh(new Date());
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoadingError('Failed to load data. Please refresh the page and try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const refreshInterval = setInterval(fetchData, 300000); // 5 minutes
    return () => clearInterval(refreshInterval);
  }, [fetchPatients, fetchConsultations, fetchAppointments]);

  const getFilteredData = () => {
    const dateFrom = new Date(filters.dateFrom);
    const dateTo = new Date(filters.dateTo);
    dateTo.setHours(23, 59, 59, 999);

    const filteredPatients = patients
      .filter(patient => {
        const admissionDate = patient.admission_date ? new Date(patient.admission_date) : null;
        
        const matchesDate = admissionDate && 
          admissionDate >= dateFrom &&
          admissionDate <= dateTo;
        
        const matchesSpecialty = filters.specialty === 'all' || 
          patient.department === filters.specialty;
        
        const matchesSearch = filters.searchQuery === '' ||
          patient.name.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
          patient.mrn.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
          (patient.doctor_name || '').toLowerCase().includes(filters.searchQuery.toLowerCase());

        return matchesDate && matchesSpecialty && matchesSearch;
      });

    const filteredConsultations = consultations.filter(consultation => {
      const matchesDate = new Date(consultation.created_at) >= dateFrom &&
        new Date(consultation.created_at) <= dateTo;
      
      const matchesSpecialty = filters.specialty === 'all' || 
        consultation.consultation_specialty === filters.specialty;
      
      const matchesSearch = filters.searchQuery === '' ||
        consultation.patient_name.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
        consultation.mrn.toLowerCase().includes(filters.searchQuery.toLowerCase());

      return matchesDate && matchesSpecialty && matchesSearch;
    });

    const filteredAppointments = appointments.filter(appointment => {
      const matchesDate = new Date(appointment.createdAt) >= dateFrom &&
        new Date(appointment.createdAt) <= dateTo;
      
      const matchesSpecialty = filters.specialty === 'all' || 
        appointment.specialty === filters.specialty;
      
      const matchesSearch = filters.searchQuery === '' ||
        appointment.patientName.toLowerCase().includes(filters.searchQuery.toLowerCase()) ||
        appointment.medicalNumber.toLowerCase().includes(filters.searchQuery.toLowerCase());

      return matchesDate && matchesSpecialty && matchesSearch;
    });

    return {
      title: 'IMD-Care Daily Report',
      patients: filteredPatients,
      consultations: filteredConsultations,
      appointments: filteredAppointments,
      filters,
      dateFilter: {
        startDate: filters.dateFrom,
        endDate: filters.dateTo,
        period: filters.reportType === 'daily' ? 'today' : filters.reportType as 'today' | 'week' | 'month' | 'custom'
      }
    };
  };

  const handleFilterChange = (newFilters: ReportFiltersType) => {
    setFilters(newFilters);
    setExportError(null);
  };

  const handleExport = async () => {
    if (isExporting) return;
    setExportError(null);
    setIsExporting(true);

    try {
      const filteredData = getFilteredData();
      
      // Validate data before export
      if (!filteredData.consultations.length && 
          !filteredData.patients.length && 
          !filteredData.appointments.length) {
        throw new Error('No data available for the selected period.');
      }

      // Create filename with date
      const dateStr = format(new Date(), 'yyyy-MM-dd');
      const filename = `IMD-Care_Daily_Report_${dateStr}.pdf`;

      // Generate PDF with options
      const pdfGenerator = new PdfGenerator(filteredData, {
        orientation: 'landscape',
        maxRetries: 3,
        chunkSize: 50
      });

      const pdfBlob = await pdfGenerator.generate();
      
      // Create download link
      const url = window.URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

      toast.success('Report exported successfully!');
    } catch (error) {
      console.error('Export error:', error);
      const errorMessage = error instanceof PDFGenerationError 
        ? `PDF Generation failed: ${error.message}`
        : 'Failed to export report. Please try again.';
      setExportError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsExporting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex-1 p-6">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
          <div className="flex items-center justify-center">
            <div className="w-8 h-8 border-4 border-indigo-600 border-t-transparent rounded-full animate-spin"></div>
          </div>
        </div>
      </div>
    );
  }

  if (loadingError) {
    return (
      <div className="flex-1 p-6">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 text-red-600">
            <AlertCircle className="h-5 w-5" />
            <span>{loadingError}</span>
          </div>
        </div>
      </div>
    );
  }

  const filteredData = getFilteredData();

  return (
    <div className="flex-1 p-4 md:p-6 space-y-6">
      {/* Header Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4 md:p-6">
        <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-indigo-50 rounded-lg">
              <FileText className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">Daily Reports</h1>
              <p className="text-sm text-gray-600">
                Last updated: {lastRefresh.toLocaleTimeString()}
              </p>
            </div>
          </div>
          
          <div className="flex items-center gap-3">
            <button
              onClick={fetchData}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors flex items-center gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              <span>Refresh</span>
            </button>
            
            <button
              onClick={handleExport}
              disabled={isExporting}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50"
            >
              {isExporting ? (
                <>
                  <RefreshCw className="h-4 w-4 animate-spin" />
                  <span>Exporting...</span>
                </>
              ) : (
                <>
                  <Download className="h-4 w-4" />
                  <span>Export PDF</span>
                </>
              )}
            </button>
          </div>
        </div>

        {exportError && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            <span>{exportError}</span>
          </div>
        )}
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          title="Total Consultations"
          value={filteredData.consultations.length}
          icon={<Activity className="h-6 w-6" />}
          trend={+5}
          color="indigo"
        />
        <StatCard
          title="New Admissions"
          value={filteredData.patients.length}
          icon={<Users className="h-6 w-6" />}
          trend={-2}
          color="emerald"
        />
        <StatCard
          title="Pending Appointments"
          value={filteredData.appointments.length}
          icon={<Calendar className="h-6 w-6" />}
          trend={+8}
          color="amber"
        />
        <StatCard
          title="Total Patients"
          value={patients.length}
          icon={<BarChart2 className="h-6 w-6" />}
          trend={+12}
          color="blue"
        />
      </div>

      {/* Filters Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4 md:p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Filters</h2>
        <ReportFilters onFilterChange={handleFilterChange} />
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4 md:p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Consultations by Department</h2>
          <DataChart type="pie" data={filteredData.consultations} />
        </div>
        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4 md:p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Daily Admissions Trend</h2>
          <DataChart type="line" data={filteredData.patients} />
        </div>
      </div>

      {/* Summary Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-4 md:p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Summary</h2>
        <ReportSummary data={filteredData} />
      </div>

      {/* Table Section */}
      <div className="bg-white rounded-xl shadow-sm border border-gray-200 overflow-hidden">
        <div className="p-4 md:p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">Detailed Report</h2>
        </div>
        <ReportTable data={filteredData} />
      </div>
    </div>
  );
};

export default DailyReports;