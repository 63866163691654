import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format, isValid } from 'date-fns';
import type { ExportData } from '../types/report';
import { PDFGenerationError } from './reportExport';

interface PdfGeneratorOptions {
  pageSize?: 'a4' | 'letter';
  orientation?: 'p' | 'portrait' | 'l' | 'landscape';
  maxRetries?: number;
  chunkSize?: number;
}

interface PDFErrorMetadata {
  originalError: any;
  data?: any;
  attempts?: number;
}

export class PdfGenerator {
  private doc: jsPDF;
  private data: ExportData;
  private currentY: number = 20;
  private pageWidth: number;
  private margins = { left: 20, right: 20 };
  private maxRetries: number;
  private chunkSize: number;
  private pageFormat: string;
  private pageOrientation: string;

  constructor(data: ExportData, options: PdfGeneratorOptions = {}) {
    const { 
      pageSize = 'a4', 
      orientation = 'portrait',
      maxRetries = 3,
      chunkSize = 50
    } = options;

    this.pageFormat = pageSize;
    this.pageOrientation = orientation;
    
    // Validate input data
    if (!this.validateData(data)) {
      throw new PDFGenerationError(
        'Invalid or missing data for PDF generation',
        { originalError: null, data } as PDFErrorMetadata,
        'INVALID_DATA'
      );
    }

    this.doc = new jsPDF({ 
      format: this.pageFormat, 
      orientation: this.pageOrientation as ('p' | 'portrait' | 'l' | 'landscape'),
      compress: true // Enable compression
    });
    
    this.data = this.sanitizeData(data);
    this.pageWidth = this.doc.internal.pageSize.width;
    this.maxRetries = maxRetries;
    this.chunkSize = chunkSize;
    
    // Set default font and encoding
    this.doc.setFont('helvetica');
    this.doc.setLanguage('en-US');
  }

  private validateData(data: ExportData): boolean {
    if (!data) return false;
    
    const hasArrayProperty = (obj: ExportData, prop: keyof ExportData): boolean => 
      Array.isArray(obj[prop]);
    
    return ['consultations', 'patients', 'appointments'].every(
      prop => hasArrayProperty(data, prop as keyof ExportData)
    );
  }

  private sanitizeData(data: ExportData): ExportData {
    return {
      ...data,
      consultations: data.consultations.map(consultation => ({
        ...consultation,
        patient_name: consultation.patient_name || 'N/A',
        mrn: consultation.mrn || 'N/A',
        consultation_specialty: consultation.consultation_specialty || 'N/A',
        created_at: consultation.created_at || new Date().toISOString()
      })),
      patients: data.patients.map(patient => ({
        ...patient,
        name: patient.name || 'N/A',
        mrn: patient.mrn || 'N/A',
        gender: patient.gender || 'N/A'
      })),
      appointments: data.appointments.map(appointment => ({
        ...appointment,
        patientName: appointment.patientName || 'N/A',
        medicalNumber: appointment.medicalNumber || 'N/A',
        specialty: appointment.specialty || 'N/A',
        appointment_date: this.validateDate(appointment.appointment_date) || new Date(),
        status: appointment.status || 'N/A'
      }))
    };
  }

  private validateDate(date: any): Date | null {
    if (!date) return null;
    const parsed = new Date(date);
    return isValid(parsed) ? parsed : null;
  }

  private async addTableInChunks(tableData: any[], headers: string[], title: string, startNewPage: boolean = false) {
    if (startNewPage) {
      this.doc.addPage();
      this.currentY = this.margins.left + 10;
    }

    this.doc.setFontSize(14);
    this.doc.setTextColor(44, 62, 80);
    this.doc.text(title, this.margins.left, this.currentY);
    this.currentY += 5;

    // Process table data in chunks
    for (let i = 0; i < tableData.length; i += this.chunkSize) {
      const chunk = tableData.slice(i, i + this.chunkSize);
      
      await new Promise<void>((resolve) => {
        autoTable(this.doc, {
          head: i === 0 ? [headers] : [],
          body: chunk,
          startY: this.currentY,
          margin: this.margins,
          styles: { fontSize: 10 },
          headStyles: { fillColor: [65, 84, 241] },
          alternateRowStyles: { fillColor: [245, 247, 250] },
          didDrawPage: () => {
            this.currentY = this.margins.left + 10;
          }
        });
        resolve();
      });

      this.currentY = (this.doc as any).lastAutoTable.finalY + 15;
    }
  }

  public async generate(): Promise<Blob> {
    let retries = 0;
    
    while (retries < this.maxRetries) {
      try {
        await this.generateContent();
        return this.doc.output('blob');
      } catch (error) {
        retries++;
        console.error(`PDF Generation attempt ${retries} failed:`, error);
        
        if (retries === this.maxRetries) {
          throw new PDFGenerationError(
            'Maximum retry attempts reached for PDF generation',
            { originalError: error, attempts: retries } as PDFErrorMetadata,
            'MAX_RETRIES_EXCEEDED'
          );
        }
        
        // Reset document state for retry
        this.doc = new jsPDF({ 
          format: this.pageFormat, 
          orientation: this.pageOrientation as ('p' | 'portrait' | 'l' | 'landscape'),
          compress: true
        });
        this.currentY = 20;
      }
    }

    throw new PDFGenerationError(
      'Failed to generate PDF after multiple attempts',
      { originalError: null } as PDFErrorMetadata,
      'GENERATION_FAILED'
    );
  }

  private async generateContent(): Promise<void> {
    this.addHeader();
    this.addSummaryMetrics();
    
    // Add tables with chunking - each on new page
    await this.addTableInChunks(
      this.data.patients.map(p => [
        p.name,
        p.mrn,
        p.gender
      ]),
      ['Patient Name', 'MRN', 'Gender'],
      'New Patient Admissions',
      false  // First table doesn't need new page
    );

    await this.addTableInChunks(
      this.data.consultations.map(c => [
        c.patient_name,
        c.mrn,
        c.consultation_specialty,
        format(new Date(c.created_at), 'PP')
      ]),
      ['Patient Name', 'MRN', 'Department', 'Date'],
      'Medical Consultations',
      true  // Start on new page
    );

    await this.addTableInChunks(
      this.data.appointments.map(a => [
        a.patientName,
        a.medicalNumber,
        a.specialty,
        format(new Date(a.appointment_date), 'PP'),
        a.status
      ]),
      ['Patient Name', 'MRN', 'Department', 'Date', 'Status'],
      'Pending Clinic Appointments',
      true  // Start on new page
    );

    this.addFooter();
  }

  private addHeader() {
    // Add logo if available
    // this.doc.addImage('logo.png', 'PNG', 20, 10, 40, 20);

    // Title
    this.doc.setFontSize(20);
    this.doc.setTextColor(44, 62, 80);
    const title = this.data.title || 'IMD-Care Daily Report';
    this.doc.text(title, this.pageWidth / 2, this.currentY, { align: 'center' });
    
    // Date range and generation time
    this.currentY += 10;
    this.doc.setFontSize(11);
    this.doc.setTextColor(100, 100, 100);
    const creationDate = format(new Date(), 'PPP');
    this.doc.text(`Generated: ${creationDate}`, this.pageWidth - this.margins.right, this.currentY, { align: 'right' });
    
    this.currentY += 15;
  }

  private addSummaryMetrics() {
    const metrics = [
      { label: 'Total Consultations', value: this.data.consultations.length },
      { label: 'New Admissions', value: this.data.patients.length },
      { label: 'Pending Appointments', value: this.data.appointments.length }
    ];

    this.doc.setFontSize(14);
    this.doc.setTextColor(44, 62, 80);
    this.doc.text('Summary Metrics', this.margins.left, this.currentY);
    
    this.currentY += 8;
    this.doc.setFontSize(11);
    metrics.forEach(metric => {
      this.doc.text(`${metric.label}: ${metric.value}`, this.margins.left + 10, this.currentY);
      this.currentY += 7;
    });
    
    this.currentY += 10;
  }

  private addFooter() {
    const pageCount = this.doc.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      this.doc.setPage(i);
      this.doc.setFontSize(10);
      this.doc.setTextColor(150, 150, 150);
      this.doc.text(
        `Page ${i} of ${pageCount}`,
        this.pageWidth / 2,
        this.doc.internal.pageSize.height - 10,
        { align: 'center' }
      );
    }
  }
} 