import React from 'react';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface DateFilterProps {
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (start: Date | null, end: Date | null) => void;
}

const DateFilter: React.FC<DateFilterProps> = ({ startDate, endDate, onDateChange }) => {
  return (
    <div className="flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200">
      <div className="flex items-center space-x-2">
        <Calendar className="h-5 w-5 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">Date Range:</span>
      </div>
      <div className="flex items-center space-x-2">
        <DatePicker
          selected={startDate}
          onChange={(date) => onDateChange(date, endDate)}
          selectsStart
          startDate={startDate ?? undefined}
          endDate={endDate ?? undefined}
          className="px-3 py-2 border border-gray-300 rounded-md text-sm"
          placeholderText="Start Date"
          maxDate={new Date()}
        />
        <span className="text-gray-500">to</span>
        <DatePicker
          selected={endDate}
          onChange={(date) => onDateChange(startDate, date)}
          selectsEnd
          startDate={startDate ?? undefined}
          endDate={endDate ?? undefined}
          minDate={startDate ?? undefined}
          maxDate={new Date()}
          className="px-3 py-2 border border-gray-300 rounded-md text-sm"
          placeholderText="End Date"
        />
      </div>
    </div>
  );
};

export default DateFilter; 