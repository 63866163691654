import React from 'react';
import { ArrowUpIcon, ArrowDownIcon, Activity, Users, Calendar, ClipboardList } from 'lucide-react';
import type { ExportData } from '../../types/report';

interface ReportSummaryProps {
  data: ExportData;
}

interface SummaryCardProps {
  title: string;
  value: number;
  previousValue: number;
  icon: React.ReactNode;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  title,
  value,
  previousValue,
  icon
}) => {
  const percentageChange = ((value - previousValue) / previousValue) * 100;
  const isPositive = percentageChange > 0;

  return (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-white rounded-lg">{icon}</div>
          <div>
            <h3 className="text-sm font-medium text-gray-600">{title}</h3>
            <p className="text-2xl font-bold text-gray-900">{value}</p>
          </div>
        </div>
        {percentageChange !== 0 && (
          <div
            className={`flex items-center gap-1 text-sm ${
              isPositive ? 'text-green-600' : 'text-red-600'
            }`}
          >
            {isPositive ? (
              <ArrowUpIcon className="w-4 h-4" />
            ) : (
              <ArrowDownIcon className="w-4 h-4" />
            )}
            <span>{Math.abs(percentageChange).toFixed(1)}%</span>
          </div>
        )}
      </div>
    </div>
  );
};

const ReportSummary: React.FC<ReportSummaryProps> = ({ data }) => {
  // Calculate previous day metrics for comparison
  const getPreviousDayMetrics = () => {
    // Implementation needed based on your data structure
    return {
      consultations: 0,
      admissions: 0,
      appointments: 0,
      discharges: 0
    };
  };

  const previousMetrics = getPreviousDayMetrics();

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <SummaryCard
          title="Total Consultations"
          value={data.consultations.length}
          previousValue={previousMetrics.consultations}
          icon={<Activity className="h-6 w-6 text-indigo-600" />}
        />
        {/* Add other cards similarly */}
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold mb-4">Department Breakdown</h3>
        {/* Add department breakdown table/chart */}
      </div>
    </div>
  );
};

export default ReportSummary;