import React from 'react';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell
} from 'recharts';

interface DataChartProps {
  type: 'pie' | 'line' | 'bar';
  data: any[];
  config?: {
    dateRange?: 'week' | 'month' | 'custom';
    showLegend?: boolean;
    height?: number;
    colors?: string[];
  };
}

const DEFAULT_COLORS = ['#4F46E5', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6'];

const formatDepartmentData = (data: any[]) => {
  const departmentCounts = data.reduce((acc: Record<string, number>, item) => {
    const dept = item.department || item.consultation_specialty || 'Other';
    acc[dept] = (acc[dept] || 0) + 1;
    return acc;
  }, {});

  return Object.entries(departmentCounts).map(([name, value]) => ({ name, value }));
};

const formatAdmissionsData = (data: any[], dateRange: string) => {
  const admissionsByDate = data.reduce((acc: Record<string, any>, item) => {
    const date = new Date(item.admission_date || item.created_at).toLocaleDateString();
    if (!acc[date]) {
      acc[date] = { date, emergency: 0, elective: 0, opd: 0 };
    }
    acc[date][item.admission_type?.toLowerCase() || 'elective']++;
    return acc;
  }, {});

  return Object.values(admissionsByDate);
};

const DataChart: React.FC<DataChartProps> = ({ type, data, config = {} }) => {
  const {
    dateRange = 'week',
    showLegend = true,
    height = 300,
    colors = DEFAULT_COLORS
  } = config;

  const formatChartData = () => {
    switch (type) {
      case 'pie':
        return formatDepartmentData(data);
      case 'line':
        return formatAdmissionsData(data, dateRange);
      case 'bar':
        return formatDepartmentData(data);
      default:
        return [];
    }
  };

  const chartData = formatChartData();

  if (!data.length) {
    return (
      <div className="flex items-center justify-center h-[300px] bg-gray-50 rounded-lg">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  switch (type) {
    case 'pie':
      return (
        <ResponsiveContainer width="100%" height={height}>
          <PieChart>
            <Pie
              data={chartData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={renderCustomLabel}
            >
              {chartData.map((entry, index) => (
                <Cell key={entry.name} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            {showLegend && <Legend />}
          </PieChart>
        </ResponsiveContainer>
      );

    case 'line':
      return (
        <ResponsiveContainer width="100%" height={height}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            {showLegend && <Legend />}
            <Line
              type="monotone"
              dataKey="emergency"
              stroke={colors[0]}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="elective"
              stroke={colors[1]}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="opd"
              stroke={colors[2]}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      );

    default:
      return null;
  }
};

const CustomTooltip = ({ active, payload, label }: any) => {
  if (!active || !payload) return null;

  return (
    <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-200">
      <p className="font-semibold">{label}</p>
      {payload.map((item: any, index: number) => (
        <div key={index} className="flex items-center gap-2">
          <div
            className="w-3 h-3 rounded-full"
            style={{ backgroundColor: item.color }}
          />
          <span>{item.name}: {item.value}</span>
        </div>
      ))}
    </div>
  );
};

const renderCustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  name
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
  const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default DataChart; 